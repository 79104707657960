import axios from 'axios'
import { API_ENDPOINT } from '../../../../../../config'
import store from '../../../../../../redux/stores/store'

const getDefaultState = () => {
    return {
        processing: false
    }
}

const removeAuthor = (instance, id) => {
    instance.setState({
        ...instance.state,
        processing: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/article/remove-author",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            authorId: id
        }
    }).then(response => handleRemoveAuthorResponse(instance, response, id))
        .catch(error => {
            instance.setState({
                ...instance.state,
                processing: false
            })
        })
}

const handleRemoveAuthorResponse = (instance, response, id) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                processing: false
            })
            instance.props.onRemove(id)
            break
        default:
            instance.setState({
                ...instance.state,
                processing: false
            })
            alert(response.data.message)
    }
}


const Worker = instance => {
    return {
        getDefaultState: getDefaultState,
        removeAuthor: (id) => removeAuthor(instance, id)
    }
}

export default Worker